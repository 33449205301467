import React from 'react';
import './Spinner.scss';

interface SpinnerProps {
  fullscreen?: boolean;
  inline?: boolean;
  color?: string;
}

const Spinner = ({ fullscreen = true, inline = false, color = 'white' }: SpinnerProps): JSX.Element => (
  <>
    {!inline ? (
      <div
        className={`bymelding-spinner fade-in fixed bg-black-backdrop ${
          fullscreen ? 'bymelding-spinner-large  ' : 'bymelding-spinner-small mx-auto p-3 rounded-full h-16 w-40'
        }`}
      >
        <div className="flex flex-col justify-around w-full h-full">
          <div className="m-auto lds-ellipsis">
            <div className={`bg-${color}`} />
            <div className={`bg-${color}`} />
            <div className={`bg-${color}`} />
            <div className={`bg-${color}`} />
          </div>
        </div>
      </div>
    ) : (
      <div className="bymelding-spinner-inline inline-fade-in">
        <div className="m-auto lds-ellipsis">
          <div className={`bg-${color}`} />
          <div className={`bg-${color}`} />
          <div className={`bg-${color}`} />
          <div className={`bg-${color}`} />
        </div>
      </div>
    )}
  </>
);

export default Spinner;
