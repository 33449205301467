/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import Icon, { iconOptions } from '../common/icons/Icon';

const NavbarButton = ({
  icon,
  text,
  className,
  onClick,
  children,
  ...rest
}: {
  icon?: iconOptions;
  text?: string;
  className?: string;
  onClick: any;
  children?: ReactNode;
}): JSX.Element => (
  <button
    className={`
      flex flex-col items-center justify-center 
      bg-transparent border-0 
      font-light text-5
      cursor-pointer 
      text-blue-dark visited:text-blue-dark 
      mobile-only:h-logo-small h-logo-large mobile-only:w-logo-small w-logo-large
      ${className}`}
    type="button"
    onClick={onClick}
    {...rest}
  >
    {icon && <Icon icon={icon} className="mobile-only:hidden" role="button" />}
    {children && children}
    {text && <span className="hover:underline ">{text}</span>}
  </button>
);

export default NavbarButton;
